<template>
  <card-view
      v-if="localCallGroup!==null"
      width="700"
      cardClass="position-fixed"
      style="right:0;bottom:0;top:112px;z-index: 10;overflow: auto!important;"
      rounded="false"
      elevation="2"
      contentFullSize

  >
    <template v-slot:content>
      <v-toolbar dense elevation="0" color="transparent" max-height="48px">
        <v-btn icon class="mr-2" @click="localCallGroup=null"><v-icon>mdi-close</v-icon></v-btn>
        <v-row class="rowSmall">
          <v-col cols="auto"><div class="font-weight-medium">Группа звонков #{{localCallGroup.id}}</div></v-col>
          <v-col><div class="caption">{{$tools.dateTimeFormat(localCallGroup.createTime,'DD.MM.YYYY HH:mm')}}</div></v-col>
          <v-col cols="auto"><call-direction :call-group="localCallGroup" widget class="mr-4"/></v-col>
          <v-col cols="auto"><call-group-state :call-group="localCallGroup" /></v-col>
        </v-row>
      </v-toolbar>
      <div class="px-4 pt-2">
        <v-tabs v-model="tabSelected" background-color="transparent" class="mb-2">
          <v-tab>Сводка</v-tab>
          <v-tab>Трассировка</v-tab>
        </v-tabs>
        <div v-if="tabSelected===0">
          <v-row class="rowSmall">
            <v-col>
              <v-sheet outlined rounded class="px-2 pt-2 fill-height">
                <div class="caption">Кто</div>
                
                <call-phone :phone="localCallGroup.fromPhoneNumber" copyAllow class="mr-2"/>

                <div v-if="localCallGroup.userFrom!==null" class="d-inline-block mb-2 mt-2">
                  {{$tools.userFullName(localCallGroup.userFrom, false)}}
                  <v-btn @click.stop x-small icon depressed class="ml-2" :href="'/panel/user/'+localCallGroup.userFrom.type+'/'+localCallGroup.userFrom.id+'/'" target="_blank">
                    <v-icon small>mdi-open-in-new</v-icon>
                  </v-btn>
                </div>
              </v-sheet>
            </v-col>
            <v-col>
              <v-sheet outlined rounded class="px-2 pt-2 fill-height">
                <div class="caption">Кому</div>
                <call-phone :phone="localCallGroup.toPhoneNumber" copyAllow class="mr-2"/>

                <div v-if="localCallGroup.userTo!==null" class="d-inline-block mb-2 mt-2">
                  {{$tools.userFullName(localCallGroup.userTo, false)}}
                  <v-btn @click.stop x-small icon depressed class="ml-2" :href="'/panel/user/'+localCallGroup.userTo.type+'/'+localCallGroup.userTo.id+'/'" target="_blank">
                    <v-icon small>mdi-open-in-new</v-icon>
                  </v-btn>
                </div>
              </v-sheet>
            </v-col>
          </v-row>
          <call-form :call-group="localCallGroup" :call-targets="callTargets" @change="$emit('change')" class="mb-2"/>
        </div>
        <div v-if="tabSelected===1">
          <v-sheet v-for="(callEntry, k) in localCallGroup.callEntrys" :key="'callEntry_'+k" outlined rounded class="pa-2 fill-height mb-2">
            <v-row no-gutters>
              <v-col cols="6">
                <div class="caption">Кто</div>
                <call-phone :phone="callEntry.fromPhoneNumber" class="mr-2 mb-2"/>
                <div v-if="callEntry.userFrom!==null" class="d-inline-block mb-2">
                  {{$tools.userFullName(callEntry.userFrom, false)}}
                  <v-btn @click.stop x-small icon depressed class="ml-2" :href="'/panel/user/'+callEntry.userFrom.type+'/'+callEntry.userFrom.id+'/'" target="_blank">
                    <v-icon small>mdi-open-in-new</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="6">
                <div class="caption">Кому</div>
                <call-phone :phone="callEntry.toPhoneNumber" class="mr-2 mb-2"/>
                <div v-if="callEntry.userTo!==null" class="d-inline-block mb-2">
                  {{$tools.userFullName(callEntry.userTo, false)}}
                  <v-btn @click.stop x-small icon depressed class="ml-2" :href="'/panel/user/'+callEntry.userTo.type+'/'+callEntry.userTo.id+'/'" target="_blank">
                    <v-icon small>mdi-open-in-new</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="caption">События</div>
                <div v-for="(v, k) in callEntryStates(callEntry)" :key="'callEntryState_'+k" class="mt-1">
                  <v-row no-gutters>
                    <v-col cols="3" class="pr-2">
                      <span v-if="v.state==='Appeared'">Соединение</span>
                      <span v-if="v.state==='Connected'">На линии</span>
                      <span v-if="v.state==='OnHold'">Удержание</span>
                      <span v-if="v.state==='Disconnected'">Завершен</span>
                    </v-col>
                    <v-col>{{$tools.dateTimeFormat(v.timestamp, 'DD.MM.YYYY HH:mm:ss')}}</v-col>
                    <v-col v-if="v.duration!==null">{{v.duration}} сек.</v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </div>
      </div>

    </template>
  </card-view>
</template>
  
<script>
import CardView from "@/components/CardView.vue";
import CallDirection from "@/views/Call/CallDirection.vue";
import CallGroupState from "@/views/Call/CallGroupState.vue";
import CallPhone from "@/components/CallPhone.vue";
import CallForm from "@/views/Call/CallForm.vue";
import moment from "moment";

export default {
  components: {CallForm, CallPhone, CallGroupState, CallDirection, CardView},
  props: ['callGroup','callTargets'],
  data: () => ({
    localCallGroup: null,
    tabSelected:0,
  }),
  mounted() {
    this.localCallGroup = this.callGroup;
  },
  watch: {
    callGroup(){
      this.localCallGroup = this.callGroup;
    },
    localCallGroup(){
      this.$emit('update:callGroup', this.localCallGroup)
    },
  },
  methods: {
    callEntryStates(d){
      let arr = d.callEntryStates.slice().sort((a,b)=>a.seq>b.seq ? 1 : -1);
      arr.forEach((v,k)=>{
        v.duration = k<arr.length-1 ? moment(arr[k+1].timestamp).unix()-moment(v.timestamp).unix() : null;
      });
      return arr
    }
  }
}
</script>