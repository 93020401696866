<template>
  <group :name="name">
    <v-list class="pa-0" nav>
      <v-list-item
          v-for="item in items" :key="item.id"
          @click="
            localSelects.filter((v)=>v.id===item.id).length===0 ? localSelects.push(item) : localSelects = localSelects.filter((v)=>v.id!==item.id);
            $emit('update:selects', localSelects)
          "
          class="mb-0"
      >
        <v-checkbox
            :input-value="localSelects.filter((v)=>v.id===item.id).length>0"
            class="mb-2" color="primary" dense hide-details="auto" />
        <v-icon v-if="!$tools.isEmpty(item.icon)" size="18" :color="item.color" class="mr-2">{{ item.icon }}</v-icon>
        {{ item.name }}
      </v-list-item>
    </v-list>
  </group>
</template>

<script>
import Group from "@/componentsV2/base/Group.vue";

export default {
  components: {Group},
  props:['items','name','selects'],
  data: () => ({
    localSelects:[],
  }),
  mounted() {
    this.localSelects = this.selects;
  },
  watch:{
    selects(){
      this.localSelects = this.selects;
    }
  }
}
</script>