<template>
  <div class="full-width">
    <v-row no-gutters>
      <v-col cols="auto">{{text1}}</v-col>
      <v-col class="text-right">{{text2}}</v-col>
      <v-col cols="12" class="mt-1">
        <v-progress-linear :value="value" rounded class="full-width" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['text1','text2','value']
}
</script>
