<template>
  <div>
    <div v-if="callGroup.status==='progress'">
      <call-state-item :blink="true" small color="blue darken-1" icon="mdi-phone-hangup" class="d-inline-block mr-2" />
      <span class="blue--text">В процессе</span>
    </div>
    <div v-if="callGroup.status==='success'">
      <call-state-item :blink="false" small color="green darken-1" icon="mdi-phone-hangup" class="d-inline-block mr-2" />
      <span class="green--text">Успешно</span>
    </div>
    <div v-if="callGroup.status==='fail'">
      <call-state-item :blink="false" small color="red darken-1" icon="mdi-phone-missed" class="d-inline-block mr-2" />
      <span class="red--text">Не отвечено</span>
    </div>
    <div v-if="callGroup.status==='failNonWork'">
      <call-state-item :blink="false" small color="red darken-1" icon="mdi-phone-missed" class="d-inline-block mr-2" />
      <span class="red--text">Не отвечено (нераб. время)</span>
    </div>
  </div>
</template>

<script>
import CallStateItem from "@/views/Call/CallStateItem.vue";

export default {
  components: {CallStateItem},
  props:['callGroup'],
}
</script>

