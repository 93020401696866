<template>
  <page-tabs
    ref="pageTabs"
    :tabs="[
      {icon:'mdi-phone-log',name:'Журнал звонков', path:'/call/callGroup/'},
      {icon:'mdi-calendar-check',name:'Задачи', path:'/call/task/'},
    ]"
  >
    <template v-slot:default="{index:index}">
      <call-groups v-if="index===0"
                   ref="callGroups"
                   :filter.sync="filter"
                   :callTargets="callTargets"
                   :callResults="callResults"
                   :callDirections="callDirections"
                   :statuses="statuses"
      />
      <call-tasks v-if="index===1"
                  :filter.sync="filter"
                  :callTargets="callTargets"
                  :callResults="callResults"
                  :callDirections="callDirections"
                  :statuses="statuses"
      />
    </template>
  </page-tabs>
</template>

<script>
import moment from "moment";
import {Entity_CallResult, Entity_CallTarget} from "../../../EntityStoreCacheService";
import CallGroups from "@/views/Call/CallGroups.vue";
import CallTasks from "@/views/Call/CallTasks.vue";
import PageTabs from "../../componentsV2/base/PageTabs.vue";

export default {
  components: {PageTabs, CallTasks, CallGroups},
  data:()=>({
    filter: {
      dateFrom:moment().format('DD.MM.YYYY'),
      dateTo:moment().format('DD.MM.YYYY'),
      callDirections: [],
      statuses: [],
      phone: null,
      callTarget: null,
      callResult: null,
      userClientState: [],
      users: [],
      admins: []
    },
    callTargets:[],
    callResults:[],
    callDirections:[
      {name:'Внутренний',id:0,icon:'mdi-phone-incoming-outgoing'},
      {name:'Входящий',id:1, icon: 'mdi-phone-incoming'},
      {name:'Исходящий',id:2, icon: 'mdi-phone-outgoing'},
    ],
    statuses: [
      {name:'В процессе',id:'progress', color:'#428eda'},
      {name:'Успешно',id:'success', color:'#02924d'},
      {name:'Не отвечено',id:'fail', color:'#F44336'},
      {name:'Не отвечено в нерабочее время',id:'failNonWork', color:'#607D8B'},
    ],
  }),
  watch:{
    $route(){
      this.$refs.pageTabs.indexLoad();
      this.$nextTick(()=>{
        if(typeof this.$refs.callGroups!=='undefined')
          this.$refs.callGroups.externalTrigger();
      })
    }
  },
  mounted() {
    if(typeof this.$refs.callGroups!=='undefined')
      this.$refs.callGroups.externalTrigger();

    new Entity_CallTarget()
        .selects(Entity_CallTarget.id)
        .selects(Entity_CallTarget.name)
        .selects(Entity_CallTarget.comment)
        .selects([Entity_CallTarget.callResults, Entity_CallResult.id].join('.'))
        .selects([Entity_CallTarget.callResults, Entity_CallResult.name].join('.'))
        .selects([Entity_CallTarget.callResults, Entity_CallResult.type].join('.'))
        .selects([Entity_CallTarget.callResults, Entity_CallResult.comment].join('.'))
        .order(Entity_CallTarget.sort, true)
        .page(1)
        .onPage(1000)
        .addStateCallback((v)=>{
          if(v.success) this.callTargets = v.items;
        })
        .request()
    new Entity_CallResult()
        .selects(Entity_CallResult.id)
        .selects(Entity_CallResult.name)
        .selects(Entity_CallResult.type)
        .selects(Entity_CallResult.comment)
        .selects([Entity_CallResult.callTargets, Entity_CallTarget.id].join('.'))
        .order(Entity_CallResult.type, true)
        .order(Entity_CallResult.sort, true)
        .page(1)
        .onPage(1000)
        .addStateCallback((v)=>{
          if(v.success) this.callResults = v.items;
        })
        .request()
  },
}
</script>