<template>
  <layout-view navTitle="Фильтры">
    <template v-slot:widgets>
        <v-row class="rowSmall">
          <v-col>
            <card-view title="Входящие" :loading="loadState.isLoading" minHeight="84"> 
              <template v-slot:content>
                <h2 class="font-weight-medium mb-2">{{indicators.totalIncoming}}</h2>
              </template>
            </card-view>
          </v-col>
          <v-col>
            <card-view title="Исходящие" :loading="loadState.isLoading" minHeight="84">
              <template v-slot:content>
                <h2 class="font-weight-medium mb-2">{{indicators.totalOutgoing}}</h2>
              </template>
            </card-view>
          </v-col>
          <v-col>
            <card-view title="Конверсия" :loading="loadState.isLoading" tooltip="Конверсия по результату звонка «успешно»" minHeight="84">
              <template v-slot:content>
                <div class="d-flex justify-center">
                  <progress-widget
                      :text1="typeof indicators.successResultPercent!=='undefined' ? indicators.successResultPercent+' %' : '0'"
                      :value="indicators.successResultPercent"
                  />
                  </div>
              </template>
            </card-view>
          </v-col>
          <v-col>
            <card-view title="Проставлено целей" :loading="loadState.isLoading" minHeight="84">
              <template v-slot:content>
                <h2 class="font-weight-medium mb-2">{{indicators.callsWithCallTarget}} из {{ indicators.totalCalls }}</h2>
              </template>
            </card-view>
          </v-col>
          <v-col>
            <card-view title="Проставлено результатов" :loading="loadState.isLoading" minHeight="84">
              <template v-slot:content>
                <h2 class="font-weight-medium mb-2">{{indicators.callsWithCallResult}} из {{ indicators.totalCalls }}</h2>
              </template>
            </card-view>
          </v-col>
          <v-col>
            <card-view title="Пропущенные" :loading="loadState.isLoading" minHeight="84">
              <template v-slot:content>
                <div class="d-flex justify-center">
                  <progress-widget
                      :text1="typeof indicators.failCallPercent!=='undefined' ? indicators.failCallPercent+' %' : '0'"
                      :value="indicators.failCallPercent"
                  />
                </div>
              </template>
            </card-view>
          </v-col>
        </v-row>
    </template>
    <template v-slot:nav>
      <div class="px-4">
        <date-interval name="Интервал" :valueFrom.sync="localFilter.dateFrom" :valueTo.sync="localFilter.dateTo" valueFromMinus="month:1" />

        <autocomplete-multiple name="Тип звонка" :items="callDirections" :select.sync="localFilter.callDirections" textOverflow>
          <template v-slot:itemIcon="{item}">
            <v-icon small class="mr-1" style="margin-top:-3px">{{item.icon}}</v-icon>
          </template>
          <template v-slot:selectedInfo="{item}">
            <v-icon small class="mr-1" style="margin-top:-2px">{{item.icon}}</v-icon> {{item.name}}
          </template>
        </autocomplete-multiple>


        <autocomplete-multiple name="Статус" :items="statuses" :select.sync="localFilter.statuses" textOverflow>
          <template v-slot:itemIcon="{item}">
            <v-avatar :color="item.color" size="10" class="mr-1" style="margin-top:-3px"/>
          </template>
          <template v-slot:selectedInfo="{item}">
            <v-avatar :color="item.color" size="10" class="mr-1" style="margin-top:-2px"/> {{item.name}}
          </template>
        </autocomplete-multiple>


        <text-field name="Номер телефона" :value.sync="localFilter.phone"/>

        <checkboxes name="Тип клиента" :items="[{id:'first',name:'Анон. первичный'},{id:null,name:'Из базы'}]" :selects.sync="localFilter.userClientState"/>

        <user-autocomplete-multiple name="Пользователь" :select.sync="localFilter.users"/>

        <autocomplete-multiple name="Администратор" :items="adminsFiltered" :select.sync="localFilter.admins">
            <template v-slot:itemInfo="{item}">
              {{$tools.userFullName(item)}}
            </template>
            <template v-slot:selectedInfo="{item}">
              {{$tools.userFullName(item, true)}} 
            </template>     
        </autocomplete-multiple>


        <autocomplete-multiple name="Цель звонка" :items="callTargets" :select.sync="localFilter.callTarget" textOverflow selectAllEnabled/>

        <autocomplete-single name="Результат звонка" :items="callResults" :select.sync="localFilter.callResult" clearable>
          <template v-slot:no-data>
            <div class="caption text-center">Не найдено</div>
          </template>
          <template v-slot:itemIcon="{item}">
            <v-avatar v-if="item.type==='success'" color="success" size="16" class="mr-2"><v-icon color="white" size="12">mdi-check</v-icon></v-avatar>
            <v-avatar v-else-if="item.type==='info'" color="blue" size="16" class="mr-2"><v-icon color="white" size="20">mdi-information-symbol</v-icon></v-avatar>
            <v-avatar v-else color="red" size="16" class="mr-2"><v-icon color="white" size="12">mdi-close</v-icon></v-avatar>
          </template>
          <template v-slot:selectedInfo="{item}">
            <v-avatar v-if="item.type==='success'" color="success" size="16" style="margin-top:-10px" class="mr-2"><v-icon color="white" size="12">mdi-check</v-icon></v-avatar>
            <v-avatar v-else-if="item.type==='info'" color="blue" size="16" style="margin-top:-2px"  class="mr-2"><v-icon color="white" size="20">mdi-information-symbol</v-icon></v-avatar>
            <v-avatar v-else color="red" size="16" class="mr-2"><v-icon color="white" size="12">mdi-close</v-icon></v-avatar>
            <span class="text-no-wrap">{{item.name}}</span>
          </template>
        </autocomplete-single>

      </div>
    </template>
    <template v-slot:content="{height}">
      <n-table-view
          ref="table"
          name="Группы звонков"
          :genRequestBuilder="genRequestBuilder"
          :height="height"
          :headers="[
            { text: 'ID', value: 'id', class:'text-no-wrap'},
            { text: 'Дата и время', value: 'createTime', class:'text-no-wrap' },
            { text: 'Тип звонка', value: 'callDirection', class:'text-no-wrap'},
            { text: 'Статус', value: 'status', class:'text-no-wrap'},
            { text: 'Клиент', value: 'userClient', class:'text-no-wrap'},
            { text: 'Кто', value: 'fromPhoneNumber', class:'text-no-wrap'},
            { text: 'Кому', value: 'toPhoneNumber', class:'text-no-wrap'},
            { text: 'Цель', value: 'callTarget', class:'text-no-wrap'},
            { text: 'Результат', value: 'callResult',  class:'text-no-wrap' },
            { text: 'Длит.: меню / дозвон / разговор', class:'text-no-wrap', sortable:false},
          ]"
          table-class="table9"
      >

        <template v-slot:item="{item}">
          <tr class="_call-log-item" @click="callGroup = item" :key="'cg_'+item.id">
            <td>
              {{item.id}}
            </td>
            <td>{{$tools.dateTimeFormat(item.createTime,'DD.MM.YYYY HH:mm')}}</td>
            <td>
              <call-direction :call-group="item" widget/>
            </td>
            <td>
              <call-group-state :call-group="item" class="d-inline-block"/>
              <tooltip v-if="!$tools.isEmpty(item.comment)" bottom>
                <template v-slot:default><v-icon small class="ml-2">mdi-comment-text-outline</v-icon></template>
                <template v-slot:content>
                  {{item.comment}}
                </template>
              </tooltip>
            </td>
            <td>
              <div v-if="item.userClient===null">
                <div v-if="item.userClientState==='first'">Анон.первичный</div>
                <!-- <div v-else-if="item.userClientState==='second'">Анон.вторичный</div> -->
              </div>
              <div v-else>
                {{$tools.userFullName(item.userClient)}}
                <v-btn @click.stop x-small icon depressed :href="'/panel/user/'+item.userClient.type+'/'+item.userClient.id+'/'" target="_blank">
                  <v-icon small>mdi-open-in-new</v-icon>
                </v-btn>
              </div>
            </td>
            <td>
              <call-phone :phone="item.fromPhoneNumber"/>
            </td>
            <td>
              <call-phone :phone="item.toPhoneNumber"/>
            </td>
            <td>
              <div v-if="item.callTarget!==null">{{item.callTarget.name}}</div>
            </td>
            <td>
              <div v-if="item.callResult!==null">
                <v-avatar v-if="item.callResult.type==='success'" color="success" size="16" style="margin-top:-2px"><v-icon color="white" size="12">mdi-check</v-icon></v-avatar>
                <v-avatar v-else-if="item.callResult.type==='info'" color="blue" size="16" style="margin-top:-2px"><v-icon color="white" size="20">mdi-information-symbol</v-icon></v-avatar>
                <v-avatar v-else color="red" size="16" style="margin-top:-2px"><v-icon color="white" size="12">mdi-close</v-icon></v-avatar>
                {{item.callResult.name}}
              </div>
            </td>
            <td>
              <call-record-player :callGroup="item"/>
              {{$tools.isEmpty(item.forwardDuration) ? '-' : $tools.secondsToTime(item.forwardDuration)}} /
              {{$tools.isEmpty(item.talkWaitDuration) ? '-' : $tools.secondsToTime(item.talkWaitDuration)}} /
              {{$tools.isEmpty(item.talkDuration) ? '-' : $tools.secondsToTime(item.talkDuration)}}
            </td>
          </tr>
        </template>
      </n-table-view>
      <call-group-detail :callGroup.sync="callGroup" :call-targets="callTargets" @change="$refs.table.load()" />
    </template>
  </layout-view>
</template>

<script>
import State from "@/plugins/state";
import NTableView from "@/componentsV2/base/NTableView.vue";
import LayoutView from "@/components/LayoutView.vue";
import DateInterval from "@/componentsV2/base/DateInterval.vue";
import AutocompleteMultiple from "@/componentsV2/base/AutocompleteMultiple.vue";
import AutocompleteSingle from "@/componentsV2/base/AutocompleteSingle.vue";
import UserAutocompleteMultiple from "@/componentsV2/custom/UserAutocompleteMultiple.vue";
import TextField from "@/componentsV2/base/TextField.vue";

import {
  Entity_User,
  Entity_UserProfile,
  Entity_CallGroup, Entity_CallEntry, Entity_CallResult, Entity_CallTarget, Entity_CallEntryState
} from "../../../EntityStoreCacheService";
import CallDirection from "@/views/Call/CallDirection.vue";
import CallGroupState from "@/views/Call/CallGroupState.vue";
import moment from "moment";
import Tooltip from "@/componentsV2/base/Tooltip.vue";
import CallPhone from "@/components/CallPhone.vue";
import Checkboxes from "@/componentsV2/base/Checkboxes.vue";
import CallGroupDetail from "@/views/Call/CallGroupDetail.vue";
import CallRecordPlayer from "@/views/Call/CallRecordPlayer.vue";
import CardView from "@/components/CardView";
import ProgressWidget from "../../componentsV2/base/ProgressWidget.vue";

export default {
  components: {
    ProgressWidget,
    CallRecordPlayer,
    CallGroupDetail,
    Checkboxes,
    CallPhone,
    Tooltip,
    CallGroupState,
    CallDirection,
    NTableView,
    LayoutView,
    DateInterval,
    AutocompleteMultiple,
    AutocompleteSingle,
    UserAutocompleteMultiple,
    TextField,
    CardView
  },
  props: ['filter','callTargets','callResults','callDirections','statuses'],
  data:()=>({
    loadState: new State(),
    callGroup: null,
    localFilter:null,
    timerId: null,
    indicators: {},
    admins: [],
    adminsFiltered: [],
  }),
  watch: {
    filter: {
      handler() {
        this.localFilter = this.filter;
        this.$tools.throttle(()=>{
          this.$refs.table.load();
        }, 500, 'callGroups')
      },
      deep: true,
    },
    localFilter: {
      handler() {
        this.$emit('update:filter', this.localFilter);
        this.$tools.throttle(()=>{
          this.$refs.table.load();
        }, 500, 'callGroups')
      },
      deep: true,
    },
  },
  beforeMount(){
    this.localFilter = this.filter
  },
  mounted() {
    this.adminsLoad();
    this.timerId = setInterval(() => {
      this.$tools.pageVisibleCallback(()=> {
        this.$refs.table.load();
      }, ()=>{
        this.$store.commit('alertWarning', 'Данные могли устареть. Обновите страницу или подождите 15 сек.');
      })
    }, 15000);
  },
  beforeDestroy() {
    clearInterval(this.timerId)
  },
  methods:{
    adminsLoad(){
      this.loadState.stateLoading();
      
      let rb = new Entity_User()
      .selects([Entity_User.id].join('.'))
      .selects([Entity_User.firstName].join('.'))
      .selects([Entity_User.lastName].join('.'))
      .selects([Entity_User.secondName].join('.'))
      .selects([Entity_User.imageUrl].join('.'))
      .selects([Entity_User.type].join('.'))

      rb.filterAnd([Entity_User.entity, Entity_User.type].join('.')+' =?','admin');

      rb.page(1).onPage(10000);


      rb.request((data)=>{
        this.admins = data.items
            
        this.loadState.stateSuccess();
      }, (e)=>{
        console.error(e.response.data.message);
        this.loadState.stateError();
      })
    },
    genRequestBuilder(page, onPage, sortFields){
      let rb = new Entity_CallGroup()
          .selects(Entity_CallGroup.id)
          .selects(Entity_CallGroup.callDirection)
          .selects(Entity_CallGroup.status)
          .selects(Entity_CallGroup.disconnectReasonMessage)
          .selects(Entity_CallGroup.createTime)
          .selects(Entity_CallGroup.forwardDuration)
          .selects(Entity_CallGroup.talkWaitDuration)
          .selects(Entity_CallGroup.talkDuration)
          .selects(Entity_CallGroup.comment)
          .selects(Entity_CallGroup.fromPhoneNumber)
          .selects(Entity_CallGroup.toPhoneNumber)
          .selects(Entity_CallGroup.callRecordFile)

          .selects([Entity_CallGroup.callTarget, Entity_CallTarget.id].join('.'))
          .selects([Entity_CallGroup.callTarget, Entity_CallTarget.name].join('.'))
          .selects([Entity_CallGroup.callTarget, Entity_CallTarget.comment].join('.'))

          .selects([Entity_CallGroup.callResult, Entity_CallResult.id].join('.'))
          .selects([Entity_CallGroup.callResult, Entity_CallResult.name].join('.'))
          .selects([Entity_CallGroup.callResult, Entity_CallResult.type].join('.'))
          .selects([Entity_CallGroup.callResult, Entity_CallResult.comment].join('.'))

          .selects([Entity_CallGroup.userFrom, Entity_User.id].join('.'))
          .selects([Entity_CallGroup.userFrom, Entity_User.type].join('.'))
          .selects([Entity_CallGroup.userFrom, Entity_User.firstName].join('.'))
          .selects([Entity_CallGroup.userFrom, Entity_User.lastName].join('.'))
          .selects([Entity_CallGroup.userFrom, Entity_User.secondName].join('.'))

          .selects([Entity_CallGroup.userTo, Entity_User.id].join('.'))
          .selects([Entity_CallGroup.userTo, Entity_User.type].join('.'))
          .selects([Entity_CallGroup.userTo, Entity_User.firstName].join('.'))
          .selects([Entity_CallGroup.userTo, Entity_User.lastName].join('.'))
          .selects([Entity_CallGroup.userTo, Entity_User.secondName].join('.'))

          .selects(Entity_CallGroup.userClientState)
          .selects([Entity_CallGroup.userClient, Entity_User.id].join('.'))
          .selects([Entity_CallGroup.userClient, Entity_User.type].join('.'))
          .selects([Entity_CallGroup.userClient, Entity_User.firstName].join('.'))
          .selects([Entity_CallGroup.userClient, Entity_User.lastName].join('.'))
          .selects([Entity_CallGroup.userClient, Entity_User.secondName].join('.'))
          .selects([Entity_CallGroup.userClient, Entity_User.userProfile, Entity_UserProfile.gender].join('.'))
          .selects([Entity_CallGroup.userClient, Entity_User.userProfile, Entity_UserProfile.birth].join('.'))

          .selects([Entity_CallGroup.callEntrys, Entity_CallEntry.state].join('.'))
          .selects([Entity_CallGroup.callEntrys, Entity_CallEntry.fromPhoneNumber].join('.'))
          .selects([Entity_CallGroup.callEntrys, Entity_CallEntry.toPhoneNumber].join('.'))

          .selects([Entity_CallGroup.callEntrys, Entity_CallEntry.userFrom, Entity_User.id].join('.'))
          .selects([Entity_CallGroup.callEntrys, Entity_CallEntry.userFrom, Entity_User.type].join('.'))
          .selects([Entity_CallGroup.callEntrys, Entity_CallEntry.userFrom, Entity_User.firstName].join('.'))
          .selects([Entity_CallGroup.callEntrys, Entity_CallEntry.userFrom, Entity_User.lastName].join('.'))
          .selects([Entity_CallGroup.callEntrys, Entity_CallEntry.userFrom, Entity_User.secondName].join('.'))

          .selects([Entity_CallGroup.callEntrys, Entity_CallEntry.userTo, Entity_User.id].join('.'))
          .selects([Entity_CallGroup.callEntrys, Entity_CallEntry.userTo, Entity_User.type].join('.'))
          .selects([Entity_CallGroup.callEntrys, Entity_CallEntry.userTo, Entity_User.firstName].join('.'))
          .selects([Entity_CallGroup.callEntrys, Entity_CallEntry.userTo, Entity_User.lastName].join('.'))
          .selects([Entity_CallGroup.callEntrys, Entity_CallEntry.userTo, Entity_User.secondName].join('.'))

          .selects([Entity_CallGroup.callEntrys, Entity_CallEntry.callEntryStates, Entity_CallEntryState.state].join('.'))
          .selects([Entity_CallGroup.callEntrys, Entity_CallEntry.callEntryStates, Entity_CallEntryState.seq].join('.'))
          .selects([Entity_CallGroup.callEntrys, Entity_CallEntry.callEntryStates, Entity_CallEntryState.timestamp].join('.'))

          .collect(Entity_CallGroup.collect_indicators)

      rb.page(page).onPage(onPage)

      if(this.localFilter.dateFrom)
        rb.filterAnd([Entity_CallGroup.entity, Entity_CallGroup.createTime].join('.')+'>=?',moment(this.localFilter.dateFrom, 'DD-MM-YYYY').format('YYYY-MM-DD 00:00:00'));
      if(this.localFilter.dateTo)
        rb.filterAnd([Entity_CallGroup.entity, Entity_CallGroup.createTime].join('.')+'<=?',moment(this.localFilter.dateTo, 'DD-MM-YYYY').format('YYYY-MM-DD 23:59:59'));
      if (this.localFilter.callDirections.length>0)
        rb.filterAnd([Entity_CallGroup.entity, Entity_CallGroup.callDirection].join('.')+' in (?)', this.queryArray(this.localFilter.callDirections))

      if(this.localFilter.statuses.length>0){
        let params = [];
        this.localFilter.statuses.forEach(v=>{
            params.push(v.id);
        })
        rb.filterAnd([Entity_CallGroup.entity,Entity_CallGroup.status].join('.')+" in (?)", params.join(','));
      }


      if (this.localFilter.phone)
        rb.filterAnd([Entity_CallGroup.entity,Entity_CallGroup.fromPhoneNumber].join('.')+' LIKE ? OR '+[Entity_CallGroup.entity,Entity_CallGroup.toPhoneNumber].join('.')+' LIKE ?', '%'+this.localFilter.phone+'%', '%'+this.localFilter.phone+'%');


      if(this.localFilter.userClientState.length>0){
        let arr = [];
        this.localFilter.userClientState.forEach(v=>{
          if(v.id!==null)
            arr.push(v.id)
        })

        let select3 = this.localFilter.userClientState.filter(v=>v.id===null).length>0;
        if(arr.length>0 && select3){
          rb.filterAnd([Entity_CallGroup.entity,Entity_CallGroup.userClientState].join('.')+' in (?) OR '+[Entity_CallGroup.entity,Entity_CallGroup.userClient].join('.')+' IS NOT NULL', arr.join(','));
        } else if(arr.length>0){
          rb.filterAnd([Entity_CallGroup.entity,Entity_CallGroup.userClientState].join('.')+' in (?)', arr.join(','));
        } else if(select3){
          rb.filterAnd([Entity_CallGroup.entity,Entity_CallGroup.userClient].join('.')+' IS NOT NULL');
        }
      }

      if(this.localFilter.users.length>0||this.localFilter.admins.length>0){
        let users = [];
        this.localFilter.users.forEach(v=>{
          users.push(v.id)
        })

        let admins = [];
        this.localFilter.admins.forEach(v=>{
          admins.push(v.id)
        })

        let arr = users.concat(admins);

        rb.filterAnd(
            [Entity_CallGroup.entity,Entity_CallGroup.userClient,Entity_User.id].join('.')+' in (?)'
            +' OR '+[Entity_CallGroup.entity,Entity_CallGroup.userFrom, Entity_User.id].join('.')+' in (?)'
            +' OR '+[Entity_CallGroup.entity,Entity_CallGroup.userTo, Entity_User.id].join('.')+' in (?)'
            , arr.join(','), arr.join(','), arr.join(',')
        );
      }

      if(this.localFilter.callTarget!==null&&this.localFilter.callTarget.length>0)
        rb.filterAnd([Entity_CallGroup.entity, Entity_CallGroup.callTarget, Entity_CallTarget.id].join('.')+' in (?)', this.queryArray(this.localFilter.callTarget))
      if(this.localFilter.callResult)
        rb.filterAnd([Entity_CallGroup.entity, Entity_CallGroup.callResult, Entity_CallResult.id].join('.')+'=?', this.localFilter.callResult.id)


      rb.addStateCallback((v)=>{
        this.loadState.setRequestBuilderState(v)
        if(v.success) {
          this.indicators = v.collect.indicators

          this.adminsFiltered = this.admins.filter(a=>this.indicators.adminIds.includes(a.id));
        }
      })  

      sortFields.forEach(v=>{
        rb.order(v.field, v.isAsc)
      })

      return rb;
    },
    queryArray(items,key='id') {
      let arr = [];
      items.forEach(s=>{
        arr.push(s[key]);
      });
      return arr;
    },
    externalTrigger() {
      if (typeof this.$router.currentRoute.query.userId !== 'undefined'){
        this.localFilter.dateFrom = moment().subtract(1, "month").format('DD.MM.YYYY');
        this.localFilter.phone = null;
        new Entity_User()
            .selects(Entity_User.id)
            .selects(Entity_User.firstName)
            .selects(Entity_User.lastName)
            .selects(Entity_User.secondName)
            .selects(Entity_User.type)
            .selects([Entity_User.userProfile, Entity_UserProfile.gender].join('.'))
            .selects([Entity_User.userProfile, Entity_UserProfile.birth].join('.'))
            .filterAnd([Entity_User.entity, Entity_User.id].join('.')+'=?', this.$router.currentRoute.query.userId)
            .addStateCallback((v)=>{
              if(v.success) {
                this.localFilter.users = v.items
              }
            })
            .request()
        this.$router.replace({ path: this.$route.path, query: {} });
      }
      if (typeof this.$router.currentRoute.query.phone !== 'undefined'){
        this.localFilter.dateFrom = moment().subtract(1, "month").format('DD.MM.YYYY');
        this.localFilter.users = [];
        this.localFilter.phone = this.$router.currentRoute.query.phone;
        this.$router.replace({ path: this.$route.path, query: {} });
      }
    }
  }
}
</script>