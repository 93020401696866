<template>
  <div>
    <portal to="tabs">
      <v-tabs v-model="index" background-color="transparent">
        <v-tab v-for="(v, k) in tabs" :key="k" @click="$router.push(v.path)">
          <v-icon class="pr-2">{{v.icon}}</v-icon>
          {{v.name}}
        </v-tab>
      </v-tabs>
    </portal>
    <div>
      <slot name="default" v-bind:index="index"/>
    </div>
  </div>
</template>

<script>
export default {
  props: ['tabs'],
  data:()=>({
    index:0,
  }),
  mounted(){
    this.indexLoad();
  },
  watch:{
    $router(){
      console.log(this.$router.currentRoute.path);
    },
    index(){
      /*
      if(typeof this.tabs[this.index]!=='undefined'){
        //let path = (process.env.NODE_ENV==='development' ? '' : '/panel')+ this.tabs[this.index].path;
        //history.pushState({}, '', path);

        if(this.$router.currentRoute.fullPath!==this.tabs[this.index].path)
          this.$router.push(this.tabs[this.index].path);
      }

       */
    }
  },
  methods:{
    indexLoad(){
      this.tabs.forEach((v,k)=>{
        if(v.path===this.$router.currentRoute.path)
          this.index = k;
      });
    }
  }
}
</script>
